.images-and-text {
    position: relative;
    &__first {
        width: 46%;
    }
    &__second {
        width: calc(54% + 15px);
        margin-left: -15px;
        margin-top: 30px;
        @include from(m) {
            width: calc(54% + 25px);
            margin-left: -25px;
            margin-top: 70px;
        }
        @include from(l) {
            margin-top: 110px;
        }
        @include from(xl) {
            width: calc(54% + 40px);
            margin-left: -40px;
            margin-top: 180px;
        }
    }
    &__images {
        @include from(l) {
            padding: 0 8vw !important;
        }
        @include from(xl) {
            padding: 0 6.5vw 0 11vw !important;
        }

    }
    &__text {
        @include from(l) {
            margin-left: -2vw;
        }
        @include from(xl) {
            margin-left: 0;
        }
    }

    .clower {
        top: -60px;
        right: -15px;
        width: 52px;
        height: 52px;
        color: #FCCCD2;
        animation: graphic 1.25s linear alternate infinite;
        @media screen and (prefers-reduced-motion: reduce), (update: slow) {
            animation: none;
        }
        @include from(m) {
            top: -70px;
            right: 59px;
            width: 88px;
            height: 88px;
        }
        @include from(l) {
            top: -90px;
            right: 139px;
            width: 104px;
            height: 104px;
        }
        @include from(xl) {
            top: -98px;
            right: 196px;
            width: 151px;
            height: 151px;
        }
    }
    .heart1 {
        left: -10px;
        bottom: -10px;
        width: 57px;
        height: 48px;
        color: #75d0aa;
        animation: graphic 1.25s 350ms linear alternate infinite;
        @media screen and (prefers-reduced-motion: reduce), (update: slow) {
            animation: none;
        }
        @include from(m) {
            left: 10px;
            bottom: 0;
            width: 105px;
            height: 88px;
        }
        @include from(l) {
            left: 63px;
            bottom: -20px;
            width: 126px;
            height: 105px;
        }
        @include from(xl) {
            left: 84px;
            bottom: -40px;
            width: 202px;
            height: 169px;
        }
    }
    .heart2 {
        display: none;
        @include from(xl) {
            display: block;
            color: #FCCCD2;
            right: 117px;
            bottom: -30px;
            width: 202px;
            height: 169px;
            animation: graphic 1.25s 700ms linear alternate infinite;
            @media screen and (prefers-reduced-motion: reduce), (update: slow) {
                animation: none;
            }
        }
    }
}
