.edit {
    position: fixed;
    right: 15px;
    bottom: 15px;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: $colorGovernor;
    color: $colorTuftBush;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.5);
    svg {
        width: 50%;
        height: auto;
        margin-right: -6%;
        margin-top: -6%;
    }
}
