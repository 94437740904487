.radiobutton {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
    @include from(m) {
        margin-bottom: 20px;
    }
    &:last-child {
        margin-bottom: 0;
    }

    span {
        user-select: none;
        font-size: 14px;
        line-height: 1;
        @include from(m) {
            font-size: 16px;
        }
        @include from(xl) {
            font-size: 18px;
        }
    }

    i {
        position: relative;
        flex: 0 0 27px;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        margin-right: 10px;
        border: 1px solid $colorGovernor;
        &:after {
            position: absolute;
            content: '';
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background: $colorGovernor;
            top: 5px;
            left: 5px;
            opacity: 0;
            transition: all 0.25s ease-out;
        }
    }

    input {
        position: absolute;
        width: 100%;
        height: 100%;
        //opacity: 0;
        pointer-events: none;
        appearance: none;
        border: 0;
    }

    input:checked + i {
        &:after {
            opacity: 1;
        }
    }

    &:hover {
        input:not(:checked) + i:after {
            transform: scale(0.75);
            opacity: 0.25;
        }
    }
}
