.chapter-nav {
    position: fixed;
    width: 100%;
    top: 100%;
    z-index: 10;
    transform: translateY(-52px);
    transition: transform 0.45s $easeOutCubic;
    user-select: none;
    &.-open {
        transform: translateY(-100%);
    }

    @include from(m) {
        transform: translateY(-74px);
    }
    @include from(xl) {
        transform: translateY(-91px);
    }

    &__progress {
        will-change: width;
        height: 52px;
        @include from(m) {
            height: 74px;
        }
        @include from(xl) {
            height: 91px;
        }
    }

    &__item, &__button {
        font-size: 13px;
        line-height: 1;
        padding-top: 10px;
        padding-bottom: 10px;
        @include from(m) {
            font-size: 16px;
            padding-top: 16px;
            padding-bottom: 14px;
        }
        @include from(xl) {
            font-size: 18px;
            padding-top: 19px;
            padding-bottom: 17px;
        }
    }

    &__button {
        height: 52px;
        @include from(m) {
            height: 74px;
        }
        @include from(xl) {
            height: 91px;
        }
        &-open {
            display: block;
            .-open & {
                display: none;
            }
        }
        &-close {
            display: none;
            .-open & {
                display: block;
            }
        }
    }

    &__item {
        border-bottom: 1px solid $colorDownriver;
        a {
            display: flex;
            align-items: center;
        }
        &-image {
            background: rgba(255,255,255,.1);
            width: 47px;
            height: 32px;
            margin-right: 17px;
            @include from(m) {
                width: 64px;
                height: 44px;
                margin-right: 22px;
            }
            @include from(xl) {
                width: 80px;
                height: 55px;
                margin-right: 40px;
            }
        }
    }
}
