.page-menu {
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    background: $colorZodiac;
    color: $colorCinderella;

    @include from(l) {
        display: none !important;
    }

    &__wrapper {
        width: 100%;
        height: 100%;
        padding-top: 109px;

        @include from(m) {
            padding-top: 154px;
        }
        @include from(l) {
            padding-top: 179px;
        }
        @include from(xl) {
            padding-top: 207px;
        }
        &-inner {
            overflow: auto;
            height: 100%;
            padding-bottom: 40px;
        }
    }

    &__item {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        &-toggle {
            background: transparent;
            color: inherit;
            margin-left: 8px;
            &[aria-expanded="true"] {
                transform: scaleY(-1);
            }
        }
    }

    &__submenu {
        width: 100%;
        font-size: 16px;
        line-height: 20px;
        padding: 1.5em 0 1.5em 22px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-weight: 400;

        [aria-expanded="false"] + & {
            display: none;
        }

        &-item {
            margin-bottom: 1.25em;
            text-decoration: underline;
            text-decoration-thickness: 1px;
            text-decoration-color: transparent;
            text-underline-offset: 5px;
            transition: text-decoration-color 0.375s $easeOutQuad;
            &:last-child {
                margin-bottom: 0;
            }
            &:hover {
                text-decoration-color: $colorMacNCheese;
            }
            &.selected {
                text-decoration-color: $colorMacNCheese;
            }
        }
    }
}
