.page-header {
    color: $colorGovernor;
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    // pointer-events: none;
    z-index: 100;
    will-change: color;
    transition: color 0.2s ease-out, background-color 0.2s ease-out;

    .has-solid-header & {
        background: $colorTuftBush;
    }

    a, button {
        // pointer-events: auto;
    }

    .js & {
        position: fixed;
    }

    .has-dark-top:not(.has-solid-header) &,
    .has-dark-hero:not(.has-solid-header) &,
    .is-modal-open & {
        color: $colorCinderella;
    }

    &__wrapper {
        transition: height 0.2s ease-out;
        will-change: height;
        height: 80px;
        @include from(m) {
            height: 124px;
        }
        @include from(l) {
            height: 149px;
        }
        @include from(xl) {
            height: 175px;
        }
        .has-collapsed-header & {
            height: 65px;
            @include from(m) {
                height: 65px;
            }
            @include from(l) {
                height: 65px;
            }
            @include from(xl) {
                height: 90px;
            }
        }
    }

    &__skip {
        position: fixed;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        pointer-events: none;
        &:focus {
            opacity: 1;
            pointer-events: auto;
        }
    }

    &__logo {
        flex: 0 1 auto;
        width: 190px;
        height: 21px;
        @include from(m) {
            width: 308px;
            height: 34px;
        }
        @include from(xl) {
            width: 410px;
            height: 45px;
        }
        svg {
            width: 100%;
            height: 100%;
        }
        .wink {
            display: none;
        }
        .using-mouse &:hover {
            .wink {
                display: block;
            }
            .open {
                display: none;
            }
        }
    }

    &__search {
        * {
            vector-effect: non-scaling-stroke;
        }
        &-close {
            opacity: 0;
        }
        width: 19px;
        height: 18px;
        margin-right: 10px;
        @include from(m) {
            width: 28px;
            height: 26px;
            margin-right: 14px;
        }
        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__searchbutton {
        padding: 6px 13px 5px;
        border-radius: 8px;
        border: 1px solid currentColor;
        user-select: none;
        transition: all 0.2s ease-out;
        .using-mouse &:hover {
            background: $colorZodiac;
            border-color: $colorZodiac !important;
            color: $colorCinderella;
        }
        .has-dark-hero:not(.has-solid-header) & {
            border-color: $colorMacNCheese;
        }
        .has-dark-top:not(.has-solid-header) & {
            border-color: $colorMacNCheese;
            .using-mouse &:hover {
                background: $colorGovernor;
                border-color: $colorGovernor;
            }
        }
        @include from(xl) {
            padding: 7px 16px 8px;
        }
    }

    &__language {
        background: transparent;
        color: inherit;
        padding: 0;
        &-dialog {
            position: absolute;
            top: 47px;
            right: 0;
            padding: 20px 25px;
            font-size: 16px;
            line-height: 1.5;
            @include from(xl) {
                padding: 30px 35px;
                font-size: 18px;
                top: 58px;
            }
            &.-left {
                right: auto;
                left: 0;
            }
            .has-dark-top & {
                background-color: $colorGovernor;
            }
        }
    }

    &__hamburger {
        i {
            display: block;
            width: 19px;
            height: 2px;
            margin-bottom: 5px;
            background: currentColor;
            @include from(m) {
                width: 28px;
                height: 3px;
                margin-bottom: 7px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__items {
        .hide-menu & {
            display: none;
        }
        @include from(l) {
            margin: 0 3%;
        }
        @include from(xl) {
            margin: 0 6%;
        }
    }

    &__item {
        display: block;
        font-size: 20px;
        line-height: 26px;
        border-bottom: 1px solid transparent;
        transition: border-bottom-color 0.375s $easeOutQuad;
        @include from(xl) {
            font-size: 22px;
            line-height: 28px;
        }
        &:hover {
            border-color: currentColor;
            .has-dark-top:not(.has-solid-header) &,
            .has-dark-hero:not(.has-solid-header) & {
                border-bottom-color: $colorMacNCheese;
            }
        }
        &.selected {
            border-bottom: 1px solid currentColor;
            .has-dark-top:not(.has-solid-header) &,
            .has-dark-hero:not(.has-solid-header) & {
                border-bottom-color: $colorMacNCheese;
            }
        }

    }

    &__submenu {
        position: absolute;
        top: calc(100% - 1px);
        left: -30px;
        min-width: 288px;
        padding-top: 11px;
        display: none;

        [aria-expanded="true"] + & {
            display: block;
        }

        &-wrapper {
            padding: 30px 32px;
            background: $colorZodiac;
            color: $colorCinderella;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .has-dark-top & {
                background: $colorGovernor;
            }
        }

        &-item {
            margin-bottom: 1.25em;
            text-decoration: underline;
            text-decoration-thickness: 1px;
            text-decoration-color: transparent;
            text-underline-offset: 5px;
            transition: text-decoration-color 0.375s $easeOutQuad;
            &:last-child {
                margin-bottom: 0;
            }
            &:hover {
                text-decoration-color: $colorMacNCheese;
            }
            &.selected {
                text-decoration-color: $colorMacNCheese;
            }
        }
    }
}
