.checkbox {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
    @include from(m) {
        margin-bottom: 20px;
    }
    &:last-child {
        margin-bottom: 0;
    }

    span {
        user-select: none;
        font-size: 14px;
        line-height: 1;
        @include from(m) {
            font-size: 16px;
        }
        @include from(xl) {
            font-size: 18px;
        }
    }

    i {
        display: flex;
        flex: 0 0 25px;
        width: 25px;
        height: 25px;
        margin-right: 10px;
        color: $colorGovernor;
        border: 1px solid currentColor;
        &:after {
            content: '✓';
            color: currentColor;
            opacity: 0;
            font-family: sans-serif;
            font-weight: bold;
            font-size: 16px;
            line-height: 25px;
            margin-left: 3px;
            transition: opacity 0.25s ease-out;
        }
    }

    input {
        position: absolute;
        width: 100%;
        height: 100%;
        //opacity: 0;
        pointer-events: none;
        appearance: none;
        border: 0;
    }

    input:checked + i:after {
        opacity: 1;
    }

    &:hover {
        input:not(:checked) + i:after {
            opacity: 0.5;
        }
    }
}
