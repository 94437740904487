.hero-text-animation {
    overflow: hidden;
    position: relative;
    padding-top: 135px;
    @include from(m) {
        padding-top: 210px;
    }
    @include from(mp) {
        padding-top: 10vh;
        height: 100vh;
        @supports (--custom:property) {
            height: calc(var(--vh, 1vh) * 100);
        }
    }

    &__left {
        @include from(mp) {
            margin-left: -5vw;
        }
        @include from(xl) {
            margin-left: -2.5vw;
        }
    }

    &__right {
        @include from(mp) {
            margin-right: -5vw;
        }
        @include from(xl) {
            margin-right: -2.5vw;
        }
    }

    .clouds {
        top: 45%;
        left: -34px;
        transform: scale(0.55);
        transform-origin: 0 0;
        @include from(mp) {
            top: 24%;
            transform: translateX(-30%);
        }
    }

    .heart {
        top: 66%;
        left: 6%;
        transform: scale(0.375);
        transform-origin: 0 0;
        color: #75D0AA;
        @include from(mp) {
            top: 80%;
            transform: scale(0.7);
            left: 35%;
        }
    }

    .flower {
        top: 53%;
        right: 0%;
        transform: scale(0.45);
        transform-origin: 0 0;
        color: #FCCCD2;
        @include from(mp) {
            top: 20%;
            transform: scale(0.8);
            right: 32%;
        }
    }

    .clower {
        top: 21%;
        right: -10%;
        transform: scale(0.45);
        color: #BEB5E5;
        @include from(mp) {
            top: 34%;
            transform: scale(0.75);
            right: -1.5%;
        }
    }
}
