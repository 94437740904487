/**
* --- Base setup and resets --------------------------------------------------------------------------------------------
*/

*, *::before, *::after {
    box-sizing: inherit;
}

html {
    font-size: 100%;
    text-size-adjust: 100%;
    height: 100%;
    box-sizing: border-box;
}

html, body {
    background: $colorBackground;
    color: $colorText;
}

body {
    position: relative;
    overflow-x: hidden;

    font-family: $fontDefault;
    font-weight: $fontWeightBase;
    font-size: 18px;
    line-height: 1.4;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    padding-top: 109px;

    &.has-hero {
        padding-top: 0;
    }

    &.has-dark-before:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 109px;
        background: $colorZodiac;
        @include from(m) {
            height: 154px;
        }
        @include from(l) {
            height: 179px;
        }
        @include from(xl) {
            height: 207px;
        }
    }

    @include from(m) {
        font-size: 20px;
        padding-top: 154px;
    }
    @include from(l) {
        padding-top: 179px;
    }
    @include from(xl) {
        font-size: 22px;
        padding-top: 207px;
    }
}


h1, h2, h3, h4, h5, h6 {
    font-size: 1em;
    font-weight: $fontWeightBase;
    margin: 0;
}

p, small, li {
    display: block;
    margin: 0;
}

ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

img {
    display: block;
    width: 100%;
    height: auto;
    border: 0;
}

svg {
    display: block;
}

figure {
    margin: 0;
}

a, input, button {
    -ms-touch-action: none !important;
}

textarea {
    resize: none;
}

select {
    border-radius: 0;
}

summary {
    cursor: pointer;
    user-select: none;
    list-style-type: none;
    &::-webkit-details-marker { display: none; }
    &::-moz-list-bullet { display:none; list-style-type: none; }
    &:before {
        display: none !important;
    }
}

input::-ms-clear {
    display: none;
}
input:not([type='radio']):not([type='checkbox']), textarea {
    appearance: none;
    border-radius: 0;
}

fieldset {
    border: 0;
    padding: 0;
    margin: 0;
}

address {
    font-style: normal;
}

a {
    color: inherit;
    text-decoration: none;
}

button {
    cursor: pointer;
    appearance: none;
    border: 0;
    &:not(:focus) {
        outline: 0;
    }
}

.no-outline {
    button, a, input, label, textarea, select, option, summary {
        outline: none !important;
    }
}

.lazyload, .lazyloading {
    opacity: 0;
    transform: translateZ(0);
    &.lazyscale {
        transform: translateZ(0) scale(1.1);
        @media screen and (prefers-reduced-motion: reduce), (update: slow) {
            transform: translateZ(0);
        }
    }
}

.lazyloaded {
    opacity: 1;
    transition: opacity 1s $easeInOutSine;
    &.lazyscale {
        transform: translateZ(0) scale(1);
        transition: opacity 1s $easeInOutSine, transform 3.5s $easeOutCirc;
    }
    @media screen and (prefers-reduced-motion: reduce), (update: slow) {
        transition-duration: 250ms !important;
    }
}

.no-js {
    img.lazyload {
        display: none;
    }
}

a, button, summary {
    .arrow-left, .arrow-right, .arrow-up, .arrow-right-up, .arrow-down {
        display: inline-block;
        transition: transform 0.375s $easeOutQuad;
    }
    .arrow-left:after {
        content: '←';
    }
    .arrow-right:after {
        content: '→';
    }
    .arrow-right-up:after {
        content: '↗';
    }
    .arrow-up:after {
        content: '↑';
    }
    .arrow-down:after {
        content: '↓';
    }
    .using-mouse &:hover:not([disabled]) {
        .arrow-left {
            transform: translateX(-0.3em);
        }
        .arrow-right {
            transform: translateX(0.3em);
        }
        .arrow-right-up {
            transform: translate(0.3em, -0.3em);
        }
        .arrow-up {
            transform: translateY(-0.3em);
        }
        .arrow-down {
            transform: translateY(0.3em);
        }
    }
}

@keyframes graphic {
    0%   {transform: translate3d(0,0,0) scale3d(1,1,1) skewY(-0.75deg);}
    50%  {transform: translate3d(0,2%,0) scale3d(.985,1.02,1) skewY(0.5deg);}
    100% {transform: translate3d(0,4%,0) scale3d(1.015,.98,1) skewY(-0.75deg);}
}

.grecaptcha-badge { visibility: hidden; }
