.page-search {
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    background: $colorZodiac;
    color: $colorCinderella;

    &__wrapper {
        width: 100%;
        padding-top: 109px;
        @include from(m) {
            padding-top: 154px;
        }
        @include from(l) {
            padding-top: 179px;
        }
        @include from(xl) {
            padding-top: 207px;
        }
    }
}
