.play-icon {
    overflow: visible;
    width: 15%;
    height: auto;
    max-width: 158px;
    max-height: 158px;
    circle {
        transform-origin: 50% 50%;
        transition: transform 0.2s ease-out;
        a:hover & {
            transform: scale(1.1);
        }
    }
}
