@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 0.0;
    }
    50% {
        opacity: 1.0;
    }
    100% {
        transform: scale(1.4);
        opacity: 0.0;
    }
}

.hotspot {
    position: absolute;
    &.js-is-active {
        z-index: 3;
    }

    &__button {
        display: block;
        width: 30px;
        height: 30px;
        cursor: pointer;
        transform: translate(-50%, -50%);
        background: $colorMacNCheese;
        color: $colorZodiac;
        font-size: 16px;
        font-weight: 600;
        line-height: 30px;
        text-align: center;
        border-radius: 30px;
        position: relative;
        z-index: 2;
        @include from(l) {
            font-size: 16px;
            line-height: 28px;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 1px solid $colorMacNCheese;
            animation: pulse 1.25s infinite ease-in-out;
        }
        &[aria-expanded="true"], .hotspot__mobile-dialog & {
            color: $colorMacNCheese;
            background: $colorZodiac;
            &:before {
                display: none;
                animation: none;
            }
        }
        .hotspot__mobile-dialog & {
            transform: none;
        }
    }
    &__dialog {
        display: none;

        @include from(mp) {
            position: absolute;
            display: flex;
            flex-direction: column;
            top: -43px;
            left: -34px;
            background: $colorMacNCheese;
            color: $colorZodiac;
            line-height: 1.5;
            padding: 78px 24px 24px 24px;
            font-size: 16px;
            width: 360px;
        }

        &[hidden] {
            display: none;
        }

        &.left {
            left: auto;
            right: calc(100% - 34px);
            //justify-content: flex-end;
        }

        &-label {
            font-weight: 900;
            margin-bottom: 1em;
            font-size: 16px;
            line-height: 1.5;
            @include from(l) {
                font-size: 20px;
            }
        }

        &-pagination {
            position: absolute;
            display: flex;
            top: 32px;
            right: 24px;
            .left & {
                right: auto;
                left: 24px;
            }
        }
    }

    &__pagination {
        background: transparent;
        font-size: 18px;
        line-height: 20px;
        padding: 0;
        &:first-child {
            margin-right: 8px;
        }
    }

    &__mobile-dialog {
        margin-top: -45px;
        @include from(mp) {
            display: none;
        }
        &-inner {
            background: $colorMacNCheese;
            color: $colorZodiac;
            padding: 24px;
            font-size: 14px;
            line-height: 1.5;
        }
        &-close {
            padding: 12px 8px;
            background: transparent;
        }
    }
}
