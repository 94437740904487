@mixin spacecat-get-breakpoint-min-width($breakpoint: null) {
	$min-width: 0;

	@if type-of($breakpoint) == number {
		$min-width: $breakpoint;
	}

	@if $min-width != 0 {
		@media (min-width: spacecat-convert-breakpoint-unit($min-width)) {
			@content;
		}
	}
}
