.newsletter-popup {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 50px;
    pointer-events: none;
    z-index: 10;

    &__dialog {
        position: relative;
        pointer-events: auto;
        border-radius: 8px;
        padding: 35px 25px;
        @include from(m) {
            max-width: 500px;
            margin-left: auto;
            margin-right: -20px;
            padding: 40px 30px;
        }
        @include from(l) {
            margin-right: -35px;
            padding: 50px 45px;
        }
        &-text {
            font-size: 18px;
            line-height: 1.3;
            padding-right: 30px;
            @include from(m) {
                font-size: 26px;
            }
            @include from(l) {
                font-size: 28px;
            }
        }
    }

    &__close {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 20px;
        height: 19px;
        padding: 0;
        margin: 0;
        background: transparent;
        border: 0;
        color: $colorCinderella;
        @include from(m) {
            top: 20px;
            right: 20px;
        }
        @include from(l) {
            top: 25px;
            right: 25px;
        }
    }
}
