.bullet-list {
    &__item {
        width: 39px;
        height: 39px;
        line-height: 38px;
        border-radius: 100%;
        text-align: center;
        @include from(xl) {
            width: 45px;
            height: 45px;
            line-height: 44px;
        }
    }
}
