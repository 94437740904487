@font-face {
    font-family: 'Barneombudet';
    src: url('../fonts/Barneombudet-Regular.woff2') format('woff2'), url('../fonts/Barneombudet-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Barneombudet';
    src: url('../fonts/Barneombudet-Regular_Italic.woff2') format('woff2'), url('../fonts/Barneombudet-Regular_Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Barneombudet';
    src: url('../fonts/Barneombudet-Bold.woff2') format('woff2'), url('../fonts/Barneombudet-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Barneombudet';
    src: url('../fonts/Barneombudet-Bold_Italic.woff2') format('woff2'), url('../fonts/Barneombudet-Bold_Italic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Barneombudet';
    src: url('../fonts/Barneombudet_Display-Extra_Bold.woff2') format('woff2'), url('../fonts/Barneombudet_Display-Extra_Bold.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
