.topics-header {
    .flower {
        top: -18px;
        right: -5px;
        width: 46px;
        height: 46px;
        color: #BEB5E5;
        animation: graphic 1.25s linear alternate infinite;
        @media screen and (prefers-reduced-motion: reduce), (update: slow) {
            animation: none;
        }
        @include from(m) {
            top: 48px;
            right: -11px;
            width: 85px;
            height: 85px;
        }
        @include from(l) {
            top: -29px;
            right: 19.5vw;
        }
        @include from(xl) {
            top: -80px;
            right: 17vw;
            width: 135px;
            height: 135px;
        }
    }
}
