/*
*   Fonts
*
 */
$fontWeightBase: 400;
$fontSizeBase: 18px;
$lineHeightBase: (26/18);
$fontDefault: 'Barneombudet', Helvetica, Arial, sans-serif;


/*
*   Colors
*
 */
$colorBlack: #1B1816;
$colorWhite: #FFF;
$colorDownriver: #0A144A;
$colorZodiac: #121C58;
$colorGovernor: #293DAA;
$colorCornflowerBlue: #687cde;
$colorCinderella: #FDEADC;
$colorTuftBush: #FFE3CF;
$colorMacNCheese: #FFC180;
$colorPigPink: #FCCCD2;
$colorPlaceholder: rgba(0,0,0,0.15);
$colorPurpleHaze: #BEB5E5;
$colorFlamePea: #EF4834;
$colorDowney: #75d0aa;
$colorText: $colorZodiac;
$colorBackground: $colorCinderella;

/*
*   Breakpoints
*
 */

// The different breakpoints are px values, but the unit has to be omitted
$small: 440;
$smallPlus: 600;
$medium: 750;
$mediumPlus: 1000;
$large: 1250;
$largePlus: 1500;
$xlarge: 1800;

$breakpoints: (
        s: $small,
        sp: $smallPlus,
        m: $medium,
        mp: $mediumPlus,
        l: $large,
        lp: $largePlus,
        xl: $xlarge,
);

/*
*   Gutters, margins, max width
*
 */

$gutterMap: (
        min: 30px,
        s: 30px,
        sp: 30px,
        m: 30px,
        mp: 30px,
        l: 70px,
        lp: 70px,
        xl: 85px
);

$outerMarginsMap: (
        min: 26px,
        s: 26px,
        sp: 26px,
        m: 60px,
        mp: 60px,
        l: 75px,
        lp: 75px,
        xl: 100px
);

$maxContentWidth: 2100px;

// If different max widths are needed, comment this in and comment the variable above out.
//
// $maxContentWidth: (
//     min: 1280px,
//     s: 1280px,
//     sp: 1280px,
//     m: 1280px,
//     mp: 1280px,
//     l: 1280px,
//     lp: 1440px,
//     xl: 1600px
// );

// Which unit should the breakpoints use? em, px or rem (em is default)
$breakpointsUnit: px; // PX, EM or REM Media Queries? -> https://zellwk.com/blog/media-query-units/

// Grid plugin settings
$grid-breakpointMap: $breakpoints;
$grid-breakpoints: (s, sp, m, mp, l, lp, xl);
$grid-columns: 12;
$grid-max-width: $maxContentWidth; //Should include support for multiple max-widths at different breakpoings. See NAF Digital
$grid-outer-margins: $outerMarginsMap;
$grid-gutters: $gutterMap;
$grid-flexbox: true;
// $grid-flexbox-test-class: 'flexbox';
$grid-pull-columns: 6;
$grid-push-columns: 6;
$grid-use-long-classnames: false;
$grid-use-em: false;
//$grid-max-width-map: $maxContentWidth; // Optional. Use if different max widths are needed at different breakpoints
