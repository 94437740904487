.accordion {
    $this: &;
    &__close {
        display: none;
    }

    &[open] {
        #{$this}__close {
            display: block;
        }
        #{$this}__open {
            display: none;
        }
    }

    ul {
        padding-left: 3em;
        padding-right: 3em;
    }

}
