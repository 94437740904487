.u {
    &-wrap {
        max-width: maxWidth() + (outerMargin()*2);
        padding-left: outerMargin();
        padding-right: outerMargin();
        margin-left: auto;
        margin-right: auto;

        @each $breakpoint in map-keys($breakpoints) {
            @include from($breakpoint) {
                padding-left: outerMargin($breakpoint);
                padding-right: outerMargin($breakpoint);
                max-width: maxWidth($breakpoint) + (outerMargin($breakpoint)*2);
            }
        }
    }

    &-margin {
        margin-bottom: 40px;
        @include from(m) {
            margin-bottom: 70px;
        }
        @include from(l) {
            margin-bottom: 100px;
        }
        @include from(xl) {
            margin-bottom: 130px;
        }
        .less-space & {
            margin-bottom: 30px;
            @include from(m) {
                margin-bottom: 50px;
            }
            @include from(l) {
                margin-bottom: 70px;
            }
            @include from(xl) {
                margin-bottom: 90px;
            }
        }
        &--skip-if-last:last-child {
            margin-bottom: 0 !important;
        }
    }

    &-padding {
        padding-top: 40px;
        padding-bottom: 40px;
        @include from(m) {
            padding-top: 70px;
            padding-bottom: 70px;
        }
        @include from(l) {
            padding-top: 100px;
            padding-bottom: 100px;
        }
        @include from(xl) {
            padding-top: 130px;
            padding-bottom: 130px;
        }
    }

    &-pad-to-logo {
        @include from(l) {
            padding-left: 53px;
        }
        @include from(xl) {
            padding-left: 71px;
        }
    }

    &-100-height {
        height: 100vh;
        @supports (--custom:property) {
            height: calc(var(--vh, 1vh) * 100);
        }
    }

    &-100-minheight {
        min-height: 100vh;
        @supports (--custom:property) {
            min-height: calc(var(--vh, 1vh) * 100);
        }
    }

    &-no-break-on-small br {
        @include until(m) {
            display: none;
        }
    }

    &-circle {
        border-radius: 100%;
        overflow: hidden;
    }

    &-link {
        border-bottom: 1px solid currentColor;
        transition: border-bottom-color 0.375s $easeOutQuad;
        .using-mouse &:hover,
        .using-mouse a:hover & {
            border-bottom-color: transparent;
        }
        &.-macncheese {
            border-bottom-color: $colorMacNCheese;
        }
    }

    &-link-inverted {
        border-bottom: 1px solid transparent;
        transition: border-bottom-color 0.375s $easeOutQuad;
        .using-mouse &:hover,
        .using-mouse a:hover & {
            border-bottom-color: currentColor;
        }
        &.-macncheese {
            .using-mouse &:hover,
            .using-mouse a:hover & {
                border-bottom-color: $colorMacNCheese;
            }
        }
    }

    &-hit {
        position: relative;
        &::before {
            content: '';
            display: block;
            width: 44px;
            height: 44px;
            position: absolute;
            left: 50%; top: 50%;
            margin: -22px 0 0 -22px;
            background-color: rgba(red, 0);
        }
    }
}
