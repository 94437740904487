.card {
    background: $colorMacNCheese;
    &--blue {
        background: $colorGovernor;
        color: $colorCinderella;
        .u-link {
            border-bottom: 1px solid $colorMacNCheese;
        }
        .u-link-inverted {
            .using-mouse &:hover {
                border-bottom-color: $colorMacNCheese;
            }
        }
    }
}
