@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

.button-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    user-select: none;
    border-radius: 8px;
    background: inherit;
    color: inherit;
    opacity: 0;
    transition: opacity 0.3s ease-out;
    [disabled] & {
        opacity: 0.95;
    }
    svg {
        width: auto;
        height: 60%;
        transform-origin: center center;
        animation: rotate 2s linear infinite;
        @include from(xl) {
            height: 50%;
        }
        circle {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
            stroke-linecap: round;
            animation: dash 1.5s ease-in-out infinite;
        }
    }
}
