.tag {
    display: block;
    border-radius: 8px;
    padding: 5px 13px;
    transition: all 0.3s ease-out;

    .using-mouse &:hover {
        background: $colorGovernor;
        border-color: $colorGovernor;
        color: $colorCinderella;
        &.bcolor-macncheese {
            background: $colorZodiac;
            border-color: $colorZodiac;
        }
    }
}
