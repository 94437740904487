.subjectpage-nav {
    @include from(l) {
        position: sticky;
        top: calc(80px + 1em);
    }
    @include from(xl) {
        top: calc(110px + 1em);
    }
    &__wrapper {
        @include from(l) {
            width: calc(100% + #{gutter(l)});
        }
        @include from(xl) {
            width: calc(100% + #{gutter(xl)});
        }
    }
}
