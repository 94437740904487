.form-receipt {
    width: 100%;
    &--error {
        color: red;
    }
    &--success {
        display: none;
        &:target {
            display: block;
        }
    }
}
