.hero-homepage {
    @include from(l) {
        height: 100vh;
        @supports (--custom:property) {
            height: calc(var(--vh, 1vh) * 100);
        }

        &__lottie {
            margin-left: -10vw;
            width: calc(100% + 10vw);
            height: 100%;
            padding-bottom: 0;
        }
    }
}
