.search-filter {
    background: transparent;
    color: inherit;
    padding: 0;

    &__dialog {
        position: absolute;
        top: 35px;
        right: -10px;
        padding: 25px 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &[hidden] {
            display: none;
        }
        a {
            display: block;
            font-size: 14px;
            line-height: 1.4;
            text-transform: uppercase;
            white-space: nowrap;
            margin-bottom: 8px;
            &.selected {
                border-bottom: 1px solid $colorMacNCheese;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
