/**
    Some people think this belongs in /vendor. I'm not one of those people
 */

/*! Flickity v2.1.1
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
    position: relative;
}

.flickity-enabled:focus {
    outline: none;
}

.flickity-viewport {
    position: relative;
    width: 100%;
    height: 100%;
}

.flickity-slider {
    width: 100%;
    height: 100%;
    display: flex;
}

/* draggable */

.flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
    position: absolute;
    border: none;
    background: transparent;
    display: block;
}

.flickity-button:hover {
    cursor: pointer;
}

.flickity-button:focus {}

.flickity-button:active {
    opacity: 0.6;
}

.flickity-button:disabled {
    opacity: 0.5;
    cursor: auto;
    /* prevent disabled button from capturing pointer up event. #716 */
    pointer-events: none;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
    top: 50%;
    width: 45px;
    height: 45px;
    background: $colorZodiac;
    color: $colorCinderella;
    border-radius: 50%;
    padding: 0;
    &:after {
        display: block;
        content: '←';
        font-size: 22px;
        line-height: 46px;
    }
    @include from(m) {
        width: 70px;
        height: 70px;
        &:after {
            font-size: 35px;
            line-height: 70px;
        }
    }
}

.flickity-prev-next-button.previous {
    transform: translate(-50%, -50%);
    left: 26px;
    @include from(m) {
        left: 60px;
    }
    @include from(l) {
        left: 75px;
    }
    @include from(xl) {
        left: 100px;
    }
}

.flickity-prev-next-button.next {
    transform: translate(50%, -50%);
    right: 26px;
    @include from(m) {
        right: 60px;
    }
    @include from(l) {
        right: 75px;
    }
    @include from(xl) {
        right: 100px;
    }
    &:after {
        content: '→'
    }
}

.flickity-prev-next-button .flickity-button-icon {
    display: none;
}

/* ---- page dots ---- */

.flickity-page-dots {
    position: absolute;
    width: 100%;
    bottom: -40px;
    left: 0;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    line-height: 1;

    @include from(m) {
        bottom: -60px;
    }
}

.flickity-rtl .flickity-page-dots {
    direction: rtl;
}

.flickity-page-dots .dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 2px;
    background: transparent;
    border: 1px solid currentColor;
    border-radius: 50%;
    cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
    background-color: currentColor;
}
