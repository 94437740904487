.cookiebar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    font-size: 14px;
    line-height: 1.4;

    &__button {
        font-size: 16px;
        line-height: 1;
        background: $colorGovernor;
        color: $colorCinderella;
        padding: 12px 24px;
        border-radius: 8px;
        @include from(xl) {
            font-size: 18px;
            padding: 15px 30px;
        }
    }
}
