.wysiwyg {
    p, ul, ol {
        margin: 1.5em 0;
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }

    ul, ol {
        padding-left: 1em;
    }

    li {
        display: list-item;
        padding-left: 0.25em;
        margin-bottom: 0.25em;
    }

    &--li-extra-space li {
        margin-bottom: 1em;
    }

    p, ul, ol, li {
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    h2, h3 {
        font-weight: 700;
        &:empty {
            display: none;
        }
        & + p, & + ol, & + ul {
            margin-top: 0;
        }
    }

    i, em {
        font-style: italic;
    }

    b, strong {
        font-weight: 700;
    }

    blockquote {
        color: $colorGovernor;
        &:before {
            content: '«';
        }
        &:after {
            content: '»';
        }
    }

    a:not([class]) {
        border-bottom: 1px solid $colorGovernor;
        transition: border-bottom-color 0.375s $easeOutQuad;
        .using-mouse &:hover {
            border-bottom-color: transparent;
        }
    }
    &.c-cinderella a:not([class]) {
        border-bottom-color: $colorMacNCheese;
    }

    &.factbox {
        h3 {
            font-weight: 900;
            margin-bottom: 1.25em;
        }
        li:not(:last-child) {
            margin-bottom: 1em;
        }
        .large-text {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 3em;
            @include from(l) {
                font-size: 28px;
                line-height: 36px;
            }
        }
    }

    .extra-large-text {
        font-size: 22px;
        font-weight: 700;
        line-height: 1.45;
        @include from(m) {
            font-size: 30px;
        }
        @include from(l) {
            font-size: 35px;
        }
        @include from(xl) {
            font-size: 45px;
        }
    }
}
