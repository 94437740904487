.page-footer {
    background: $colorZodiac;
    body.is-search & {
        background: $colorDownriver;
    }
    &__heading br {
        @include until(sp) {
            display: none;
        }
    }
    &__illustration {
        width: 194%;
        height: auto;
        @include from(mp) {
            width: 100%;
        }
    }
}
