@mixin spacecat-get-style-from-map($keyVal, $attribute: null, $unit: null, $breakpoint: null, $suffix: null, $isCompound: null) {
    $breakpoint-suffix: '';

	@if $breakpoint != null {
        @if $spacecat-breakpoint-append {
            $breakpoint-suffix: #{$spacecat-breakpoint-prefix}#{$breakpoint}#{$spacecat-breakpoint-suffix};
        }
	}

    $key: map-get($keyVal, 'key');
    $val: map-get($keyVal, 'val');

    @if type-of($val) == 'map' {
        @each $subKey, $value in $val {
            @include spacecat-get-style-from-map((key: $key + '-' + $subKey, val: $value), $attribute, $unit, $breakpoint, $suffix);
        }
    } @else if type-of($val) == list {
        $i: 0;
        @each $value in $val {
            $i: $i + 1;
            @include spacecat-get-style-from-map((key: $key + '-' + $i, val: $value), $attribute, $unit, $breakpoint, $suffix);
        }
    } @else {
        @if type-of($val) != 'number' {
            $unit: '';
        }

        @if type-of($attribute) == 'list' {
            &-#{$key}#{$breakpoint-suffix}#{$suffix} {
                @each $attr in $attribute {
                    @if type-of($val) == 'string' {
                        $val: unquote($val)
                    }
                    #{$attr}: $val#{$unit};
                }
            }
        } @else if type-of($attribute) == 'map' {
            @if $isCompound {
                @each $attr, $attrVal in $attribute {
                    @if type-of($attrVal) == 'string' {
                        $attrVal: unquote($attrVal)
                    }
                    #{$attr}: $attrVal#{$unit};
                }
            } @else {
                &-#{$key}#{$breakpoint-suffix}#{$suffix} {
                    @each $attr, $attrVal in $attribute {
                        @if type-of($attrVal) == 'string' {
                            $attrVal: unquote($attrVal)
                        }
                        #{$attr}: $attrVal#{$unit};
                    }
                }
            }

        } @else if type-of($attribute) == 'string' {
            &-#{$key}#{$breakpoint-suffix}#{$suffix} {
                @if type-of($val) == 'string' {
                    $val: unquote($val)
                }
                #{$attribute}: $val#{$unit};
            }
        }
    }
}
