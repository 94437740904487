.mailmojo {
    display: flex;
    flex-wrap: nowrap;
    &__field {
        appearance: none;
        width: 100%;
        padding: 1em 1.5em;
        flex: 1 1 auto;
        color: $colorZodiac;
        background: $colorCinderella;
        border: none;
        line-height: normal;
        &::placeholder {
            opacity: 1;
            color: inherit;
        }
    }
    &__button {
        padding: 1em 1.5em;
        flex: 0 0 auto;
        background: $colorGovernor;
        color: $colorCinderella;
        transition: all 0.3s ease-out;

        .using-mouse &:hover {
            background: $colorMacNCheese;
            color: $colorZodiac;
        }
    }
}
