.e-kurs {
    &__last {
        margin-bottom: 0 !important;
        padding-bottom: 52px;
        @include from(m) {
            padding-bottom: 74px;
        }
    }

    div[data-component="DiplomaForm"] + &__last {
        margin-top: -40px;
        @include from(m) {
            margin-top: -70px;
        }
        @include from(l) {
            margin-top: -100px;
        }
        @include from(xl) {
            margin-top: -130px;
        }
    }
}
