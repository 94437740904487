.image-and-textbox {
    .flower {
        bottom: -23px;
        right: 29px;
        width: 46px;
        height: 46px;
        color: #BEB5E5;
        animation: graphic 1.25s linear alternate infinite;
        @media screen and (prefers-reduced-motion: reduce), (update: slow) {
            animation: none;
        }
        @include from(m) {
            bottom: -50px;
            right: 48px;
            width: 101px;
            height: 101px;
        }
        @include from(l) {
            bottom: -70px;
            right: 80px;
            width: 120px;
            height: 120px;
        }
        @include from(xl) {
            bottom: -90px;
            right: 135px;
            width: 169px;
            height: 169px;
        }
    }
}
