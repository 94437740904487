.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 1;
    background: $colorGovernor;
    color: $colorCinderella;
    padding: 16px 24px;
    border-radius: 8px;
    transition: all 0.3s ease-out;

    .using-mouse &:hover {
        background: $colorZodiac;
    }
    @include from(m) {
        font-size: 20px;
        padding: 21px 30px;
    }
    @include from(xl) {
        font-size: 22px;
        padding: 29px 40px;
    }

    &--smaller {
        font-size: 12px;
        padding: 14px 24px;
        min-width: 106px;

        @include from(m) {
            font-size: 16px;
            padding: 17px 24px;
            min-width: 150px;
        }
    }

    &--orange {
        background: $colorMacNCheese;
        color: $colorZodiac;
        .using-mouse &:hover {
            background: $colorGovernor;
            color: $colorCinderella;
        }
    }

    &--rounded {
        border-radius: 70px;
    }

    &--hover-orange {
        .using-mouse &:hover {
            background: $colorMacNCheese;
            color: $colorZodiac;
        }
    }

    &--dark {
        background: $colorZodiac;
        .using-mouse &:hover {
            background: $colorMacNCheese;
            color: $colorZodiac;
        }
    }
}
