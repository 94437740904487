.breadcrumbs {
    position: absolute;
    top: 81px;
    width: 100%;
    color: $colorZodiac;
    z-index: 1;

    @include from(m) {
        top: 124px;
    }

    @include from(l) {
        top: 149px;
    }

    @include from(xl) {
        top: 175px;
    }


    .has-dark-top &,
    .has-dark-hero & {
        color: $colorCinderella;
        .u-link {
            border-bottom: 1px solid $colorMacNCheese;
        }
        .u-link-inverted {
            .using-mouse &:hover {
                border-bottom-color: $colorMacNCheese;
            }
        }
    }

    &__item--last {
        display: block;
        max-width: 22ch;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
