.hero-with-box {
    position: relative;
    font-size: 50px;
    @include from(m) {
        font-size: 70px;
    }
    @include from(l) {
        font-size: 65px;
    }
    @include from(xl) {
        font-size: 90px;
    }

    &__image {
        position: relative;
        height: calc(100vh - 1em);
        @supports (--custom:property) {
            height: calc((var(--vh, 1vh) * 100) - 1em);
        }
    }

    &__wrapper {
        position: relative;
        margin-top: -2em;
        @include from(m) {
            margin-top: -2.5em;
        }
    }
}
