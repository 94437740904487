.field {
    flex: 0 1 auto;

    &.w-75 {
        @include from(m) {
            width: calc(75% - 15px);
        }
    }
    &.w-50 {
        @include from(m) {
            width: calc(50% - 15px);
        }
    }
    &.w-25 {
        @include from(m) {
            width: calc(25% - 15px);
        }
    }

    &:not([class*="mb-"]) {
        margin-bottom: 20px;
        @include from(m) {
            margin-bottom: 35px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    &__label, label {
        display: block;
        font-size: 12px;
        line-height: (18/12);
        margin-bottom: 7px;
        @include from(m) {
            font-size: 16px;
        }
    }

    &__error, [aria-live="polite"] {
        display: flex;
        align-items: center;
        color: red;
        font-size: 12px;
        line-height: (18/12);
        margin-top: 5px;
        @include from(m) {
            font-size: 16px;
        }
        svg {
            width: 1.4em;
            height: 1.4em;
            margin-right: 7px;
        }
    }

    /*&.field--required label {
        &:after {
            display: inline;
            content: ' *';
        }
    }*/

    input, textarea {
        appearance: none;
        width: 100%;
        background: transparent;
        border: 1px solid $colorGovernor;
        font-size: 16px;
        line-height: normal;
        padding: 0.75em;
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
    }

    &--error {
        input, textarea {
            border-color: red;
        }
    }
}
