.hero-publication {
    &__wrapper {
        height: 100%;
        align-content: center;
        padding-top: 80px;
        padding-bottom: 70px;
        @include from(m) {
            padding-top: 130px;
            padding-bottom: 130px;
        }
        @include from(mp) {
            align-content: normal;
            align-items: center;
            padding-top: 15vh;
            padding-bottom: 15vh;
        }
        @include from(l) {
            padding-top: 20vh;
        }
    }

    &__image {
        position: relative;
        width: 100%;
        height: 39vh;
        margin-top: 6vh;
        @include from(m) {
            height: 38vh;
            margin-top: 8vh;
        }
        @include from(mp) {
            margin-top: 0;
            height: 100%;
        }
    }
}
