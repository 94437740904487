.searchbox {
    position: relative;
    &__field {
        width: calc(100% - 28px);
        font-size: 16px;
        line-height: 1.2;
        border: 0;
        margin: 0;
        padding: 16px 17px 15px;
        &::placeholder {
            color: inherit;
            opacity: 0.8;
        }
        &:placeholder-shown {
            text-overflow: ellipsis;
        }
        @include from(m) {
            width: calc(100% - 43px);
            font-size: 20px;
            padding: 30px 35px;
        }
        @include from(xl) {
            width: calc(100% - 46px);
            font-size: 22px;
        }
    }
    &__button {
        font-size: 14px;
        width: 56px;
        height: 56px;
        line-height: normal;
        text-align: center;
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: 0;
        padding: 0;
        transform: translateY(-50%);
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            background: inherit;
            border-radius: 50%;
            z-index: -1;
            transform: translate(-50%, -50%);
            transition: transform 0.3s ease-out;
        }
        .using-mouse &:hover:before {
            transform: translate(-50%, -50%) scale(1.1);
        }
        @include from(m) {
            width: 86px;
            height: 86px;
            font-size: 20px;
        }
        @include from(xl) {
            width: 92px;
            height: 92px;
            font-size: 22px;
        }
    }
    &__results {
        z-index: 10;
        top: 100%;
        width: calc(100% - 28px);
        padding: 16px 17px 15px;
        @include from(m) {
            width: calc(100% - 43px);
            padding: 30px 35px;
        }
        @include from(xl) {
            width: calc(100% - 46px);
        }
    }
}
