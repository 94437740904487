.children-and-young {
    position: relative;
    overflow: hidden;
    &__left {
        @include from(mp) {
            margin-left: -4vw;
        }
        @include from(xl) {
            margin-left: -2vw;
        }
    }

    &__right {
        @include from(mp) {
            margin-right: -4vw;
        }
        @include from(xl) {
            margin-right: -2vw;
        }
    }

    .clouds {
        top: 48%;
        left: -34px;
        transform: scale(0.55);
        transform-origin: 0 0;
        @include from(mp) {
            top: 11%;
            left: -91px;
            transform: scale(1);
        }
    }

    .heart {
        top: 66%;
        left: 6%;
        transform: scale(0.375);
        transform-origin: 0 0;
        color: #75D0AA;
        @include from(mp) {
            top: 79%;
            left: 33%;
            transform: scale(0.6);
        }
    }

    .flower {
        top: 57%;
        right: -20px;
        transform: scale(0.425);
        transform-origin: 0 0;
        color: #FCCCD2;
        @include from(mp) {
            top: 14%;
            right: 27%;
            transform: scale(0.6);
        }
    }

    .clower {
        top: 31%;
        right: -41px;
        transform: scale(0.4);
        color: #BEB5E5;
        @include from(mp) {
            top: 32%;
            right: -34px;
            transform: scale(0.6);
        }
    }
}
