@media print {
    .no-print {
        display: none !important;
    }

    html, body {
        background: #FFF !important;
        color: #000 !important;
    }

    .c-text, .c-zodiac, .c-governor, .c-downriver, .c-cinderella, .c-tuftbush, .c-macncheese, .c-pigpink {
        color: #000 !important;
    }

    .bg-zodiac, .bg-governor, .bg-downriver, .bg-cinderella, .bg-tuftbush, .bg-macncheese, .bg-pigpink {
        background: none !important;
    }

    .bcolor-zodiac, .bcolor-governor, .bcolor-downriver, .bcolor-cinderella, .bcolor-tuftbush, .bcolor-macncheese, .bcolor-pigpink {
        border-color: #000 !important;
    }

    .page-header {
        position: absolute !important;
        transform: none !important;
        color: #000 !important;
    }

    .breadcrumbs {
        color: #000 !important;
        &__item {
            border-bottom: none !important;
        }
    }

    .wysiwyg blockquote {
        color: #000 !important;
    }

    .wysiwyg a:not([class]) {
        border-bottom: none !important;
    }

    .hero-publication {
        background: none !important;
    }

    .flickity-prev-next-button {
        display: none !important;
    }

    .publication-segment {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .publication-factbox {
        svg {
            display: none !important;
        }
    }
}
