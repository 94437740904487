.toggle-button {
    display: block;
    font-size: 15px;
    line-height: 1;
    background: $colorTuftBush;
    color: $colorZodiac;
    padding: 16px 24px;
    border-radius: 8px;
    transition: all 0.3s ease-out;

    .using-mouse &:hover {
        background: $colorMacNCheese;
        color: $colorZodiac;
    }

    @include from(l) {
        font-size: 16px;
    }
    &.selected {
        background: $colorGovernor;
        color: $colorCinderella;
    }
    &--dark {
        .using-mouse &:hover {
            background: $colorGovernor;
            color: $colorCinderella;
        }
        &.selected {
            background: $colorZodiac;
        }
    }
}
