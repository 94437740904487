.bleed-and-textbox {
    position: relative;
    @include from(l) {
        padding-bottom: 127px;
    }
    @include from(xl) {
        padding-bottom: 180px;
    }
    &__image {
        position: relative;
        padding-bottom: percentage(333/373);
        @include from(m) {
            padding-bottom: percentage(658/768);
        }
        @include from(l) {
            padding-bottom: percentage(754/1500);
        }
    }
    &__box {
        position: relative;
        margin-top: -62px;
        @include from(m) {
            margin-top: -167px;
        }
        @include from(l) {
            margin-top: 0;
            width: 100%;
            bottom: 0;
            position: absolute;
        }
    }
}
